import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  addDoc,
  doc,
  onSnapshot,
  setDoc,
  GeoPoint,
} from "firebase/firestore";
import logo from "./crowded_pool_logo.svg";
import "./App.css";
import googleImage from "./google-play-badge.png";
import appstore from "./appstore.png";
import preview from "./preview.png";
import Button from "@mui/material/Button";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import { useLocation } from "react-router-dom";

const atob = (input: string = "") => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

  let str = input.replace(/=+$/, "");
  let output = "";

  if (str.length % 4 == 1) {
    throw new Error(
      "'atob' failed: The string to be decoded is not correctly encoded."
    );
  }
  for (
    let bc = 0, bs = 0, buffer, i = 0;
    (buffer = str.charAt(i++));
    ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    buffer = chars.indexOf(buffer);
  }

  return output;
};

const Footer = () => (
  <div className="footer">
    <p> ©2022 Apps Can Fly, LLC</p>
    <a href="mailto:support@appscanfly.com">
      <i
        className="fa fa-envelope"
        style={{ fontSize: "24px", color: "white", marginRight: "2px" }}
      ></i>
      <p> Contact Us</p>
    </a>
  </div>
);

const firebaseConfig = {
  apiKey: "AIzaSyDZ6Vh52D_nZqjgIBcYBN2S07D5rCWHPN4",
  authDomain: "kidtend-c391a.firebaseapp.com",
  projectId: "kidtend-c391a",
  storageBucket: "kidtend-c391a.appspot.com",
  messagingSenderId: "855687295623",
  appId: "1:855687295623:web:7df0f7ad690467642bfe22",
  measurementId: "G-ZLJ6N2QWXJ",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const empty = {
  id: "",
  name: "",
  address: "",
  location: {} as any,
  email: "",
};
const SignupChurch = () => {
  const { search } = useLocation();
  const church = new URLSearchParams(search).get("church");

  const [data, setData] = useState(empty);

  const [busy, setBusy] = useState(false);
  const submit = async () => {
    setBusy(true);
    try {
      if (data.location) {
        data.location = new GeoPoint(
          data.location._latitude,
          data.location._longitude
        );
      }
      if (data.id) {
        await setDoc(doc(db, "newChurch", data.id), data);
      } else {
        await addDoc(collection(db, "newChurch"), data);
      }
      setData(empty);
      alert("Request submitted. We'll contact you shortly");
    } catch {
      alert("unable to sbmit request");
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    if (church) {
      const churchData = JSON.parse(atob(church));
      setData(churchData);
    }
  }, [church]);

  return (
    <div className="App">
      <div className="container"></div>
      <div
        style={{
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Sign Up Church for KidTend </h1>
        <div
          style={{
            backgroundColor: "white",
            width: "75%",
            height: "80vh",
            padding: "30px",
            display: "flex",
            justifyContent: "center",
            margin: "20px",
          }}
        >
          <FormControl fullWidth>
            <TextField
              id="input-church-name"
              label="Church Name"
              variant="standard"
              value={data.name}
              onChange={(e) =>
                setData((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              style={{ marginTop: "1rem" }}
              multiline
            />
            <TextField
              id="input-church"
              label="Church Address"
              variant="standard"
              value={data.address}
              onChange={(e) =>
                setData((prev: any) => ({
                  ...prev,
                  address: e.target.value,
                }))
              }
              style={{ marginTop: "1rem" }}
              multiline
            />
            <TextField
              id="input-email"
              label="Email"
              variant="standard"
              value={data.email}
              onChange={(e) =>
                setData((prev: any) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
              style={{ marginTop: "1rem" }}
              multiline
            />

            <Button
              variant="contained"
              onClick={submit}
              disabled={busy || !data.address || !data.email}
              style={{ marginTop: "1rem" }}
            >
              Submit{busy ? "ing..." : ""}
            </Button>
          </FormControl>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignupChurch;
