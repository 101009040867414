import React from "react";
import logo from "./logo.png";
import "./App.css";
import googleImage from "./google-play-badge.png";
import appstore from "./appstore.png";
import preview from "./preview.png";

export const Footer = () => (
  <div className="footer">
    <p> ©2022 Apps Can Fly, LLC</p>
    <a href="mailto:support@appscanfly.com">
      <i
        className="fa fa-envelope"
        style={{ fontSize: "24px", color: "white", marginRight: "2px" }}
      ></i>
      <p> Contact Us</p>
    </a>
  </div>
);

const Screenshots = () => {
  return (
    <div className="left">
      <img src={preview} alt="preview-inmage" />
    </div>
  );
};
const Info = () => {
  return (
    <div className="right">
      <div className="logo">
        <img src={logo} alt="logo" />
        <span className="logo1">Kid</span>
        <span className="logo2">Tend</span>
      </div>
      <div className="tag">
        A simplified way to check in your kids at Church
      </div>
      <ul className="fetaures fa-ul">
        <li>
          <i className="fa-li fa fa-check-square" />
          Parents can check in their kids from their phones
        </li>
        <li>
          <i className="fa-li fa fa-check-square" />
          Parents can message sunday school teachers
        </li>
        <li>
          <i className="fa-li fa fa-check-square" />
          Sunday School teachers can post annoucements
        </li>
      </ul>

      <div className="links">
        <a
          href="https://play.google.com/store/apps/details?id=com.appscanfly.kidtend"
          target="_blank"
          rel="noreferrer"
        >
          <img src={googleImage} alt="google-play" />
        </a>
        <a
          href="https://apps.apple.com/us/app/kidtend/id1638729459"
          target="_blank"
          rel="noreferrer"
        >
          <img src={appstore} alt="appstore" />
        </a>
      </div>
    </div>
  );
};

const YoutubeEmbed = ({ embedId }: { embedId: string }) => (
  <div className="video-responsive">
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

const App = () => {
  return (
    <div className="App">
      <div className="container">
        <Screenshots />
        <Info />
      </div>{" "}
      <YoutubeEmbed embedId="e4G8rUfm1f4" />
      <Footer />
    </div>
  );
};

export default App;
